const state = {
  loginParams: {
    //遷移先URL、例：transition/campaign/225320.html
    url: '',
    //遷移目的、campaignなど
    purposeFlg: '',
    //SPAの内部=1、外部=0
    internalFlg: ''
  }
};

const getters = {
  loginParams: (state) => state.loginParams
};

const mutations = {
  updateParams(state, params) {
    state.loginParams = params;
  }
};

const actions = {
  setParams({ commit }, params) {
    commit('updateParams', params);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
