<!-- ロゴだけの共通ヘッダー、LINEキャンペーンログインなどの画面に適用 -->
<template>
  <div class="layout-container" id="return-top">
    <div id="simple-header"></div>

    <slot />

    <div id="simple-footer"></div>
  </div>
</template>

<script>
export default {
  props: [],
  methods: {}
};
</script>

<style scoped></style>
