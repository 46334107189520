//環境変数 prd = 本番環境、 dev = 開発環境
//urlは環境により変わる
export const ENV = 'prd';

// page title
export const TITLE = {
  login: 'ログイン | サッポロビール',
  myaccount: 'マイアカウントトップ | ログイン | サッポロビール',
  campaign: 'キャンペーン・CM | サッポロビール',
  unregist: '退会 | サッポロビール',
  socialcancel: 'アカウント登録の解除',
  logout: 'ログアウト | サッポロビール',
  notfound: 'お探しのページは見つかりませんでした'
};

//common
export const WEEKCHARS = [
  '（日）',
  '（月）',
  '（火）',
  '（水）',
  '（木）',
  '（金）',
  '（土）'
];
export const YEAR = '年';
export const MONTH = '月';
export const DAY = '日';
export const ZERO = '0';

export const ELLIPSIS = '...';
export const TITLE_MAX_LENGTH = '50';
export const SUBTITLE_MAX_LENGTH = '100';
export const AREA_LIST = [
  {areaName:'北海道',classArea:'area-hokkaido'},
  {areaName:'東北',classArea:'area-tohoku'},
  {areaName:'関東',classArea:'area-kanto'},
  {areaName:'中部',classArea:'area-chubu'},
  {areaName:'近畿',classArea:'area-kinki'},
  {areaName:'中国',classArea:'area-chugoku'},
  {areaName:'四国',classArea:'area-shikoku'},
  {areaName:'九州・沖縄',classArea:'area-kyusyu'}
];
export const CAMPAIGNTYPE_LIST = [
  'Webで応募',
  'ハガキで応募',
  'SNSで応募',
  'LINEで応募'
];
export const APPLICATIONTYPE_LIST = [
  '重複応募',
  '単一応募'
];
export const SPECIALANNOUNCEMENT_LIST = [
  '',
  'Null',
  '1月',
  '2月',
  '3月',
  '4月',
  '5月',
  '6月',
  '7月',
  '8月',
  '9月',
  '10月',
  '11月',
  '12月'
];
export const ENDTIME_List = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
];
//APIURL
export const APIURL =
  ENV === 'prd'
    ? 'https://api.sapporobeer.jp'
    : 'https://dev-api.sapporobeer.jp';

//cookie
export const COOKIEKEY =
  ENV === 'prd'
    ? '__Secure-SB_UI'
    : '__Secure-DEV_SB_UI';

//ログイン成功時のcookie（GA4用）
export const LOGINCOOKIEKEY =
  ENV === 'prd'
    ? '__Secure_SB_LI'
    : '__Secure-DEV_SB_LI';

// 会員登録URL
export const REGURL =
  ENV === 'prd'
    ? 'https://apply.sapporobeer.jp/webapp/form/23354_goib_38/index.do'
    : 'https://dev-apply.sapporobeer.jp/webapp/form/23439_nrib_7/index.do';

// パスワード変更URL
export const PASSRESETURL =
  ENV === 'prd'
    ? 'https://apply.sapporobeer.jp/webapp/form/23354_goib_44/index.do'
    : 'https://dev-apply.sapporobeer.jp/webapp/form/23439_nrib_3/index.do';

//メンテナンスURL
export const MAINTAINURL =
  ENV === 'prd'
    ? 'https://www.sapporoholdings.jp/maintenance/'
    : 'https://dev-www.sapporoholdings.jp/maintenance/';

//Synergy API 429 URL
export const OVERLOADURL =
  ENV === 'prd'
    ? 'https://myaccount.sapporobeer.jp/error/overload.html'
    : 'https://dev-myaccount.sapporobeer.jp/error/overload.html';

//campaign
export const CPLISTNOLOGIN =
  ENV === 'prd'
    ? 'https://www.sapporobeer.jp/campaign/index.html'
    : 'https://dev-www.sapporobeer.jp/campaign/index.html';
export const CPJSONURL =
  ENV === 'prd'
    ? 'https://www.sapporobeer.jp/campaign/list.json?time='
    : 'https://dev-www.sapporobeer.jp/campaign/list.json?time=';

export const SBWEBINQUIREURL =
  ENV === 'prd'
    ? 'https://apply.sapporobeer.jp/webapp/form/23354_goib_32/index.do'
    : 'https://dev-apply.sapporobeer.jp/webapp/form/23439_nrib_12/index.do';

export const ONLYAPPLY = '単一応募';
export const POSTCARD = 'ハガキで応募';
export const POSTMARK = '当日消印有効';
export const NOON = '（正午）';
export const NOONTIME = '12:00';
export const OPENCAMPAIGN = 'オープンキャンペーン';
export const CLOESEAMPAIGN = 'クローズキャンペーン';
export const APPLYNOW = '今すぐ応募';
export const BUYAPPLY = '購入して応募';
export const NONCAMPAIGN = '現在開催中のキャンペーンはありません。';
export const ERRCAMPAIGN = 'キャンペーン更新中';

//トーコンの有効期限が切る何秒前に更新するか
export const REFRESHBEFORE = 5;

// ログイン画面
//認証エラーメッセージ
export const AUTHERROR = {
  errorMessageAuth: 'ご入力頂いたメールアドレスまたはパスワードが異なるか、サッポロビールウェブサイト会員アカウントのご登録がありません。',
  errorMessageEmail: '入力情報が正しくありませんでした。メールアドレスを入力ください。',
  errorMessagePassword: '入力情報が正しくありませんでした。パスワードを入力ください。',
  errorNotFound:
    'アカウントの情報が見つかりませんでした。ご確認した上、再度お試しください。',
  errorNolink: '選択したサービスと連携しておりません。',
  errorSession:
    'ログインの有効期間が過ぎました。再度ログインしてください。',
  errorSever:
    'ご迷惑をおかけして申し訳ありません。システムエラーが発生いたしました。しばらく時間をおいてから再度アクセスしてください。<br>エラーが解決しない場合は、お手数ですが、メールアドレスとお試し頂いた手順を添えて、以下のページよりご連絡ください。<br><a href=' + SBWEBINQUIREURL + '>'+SBWEBINQUIREURL+'</a>',
  errorSocialExist: 'ご指定の外部サービスアカウントはすでに別の会員アカウントと連携済みのため、ご指定の会員アカウントに連携することが出来ません。<br>別の会員アカウントでログインの上、連携解除をお願いします。',
  errorSocialLink:
    '登録できませんでした。外部アカウントの情報を確認した上、もう一度お試しください。',
  errorSocialPlus:
    'ご迷惑をおかけして申し訳ありません。システムエラーが発生いたしました。しばらく時間をおいてから再度アクセスしてください。<br>エラーが解決しない場合は、お手数ですが、メールアドレスとお試し頂いた手順を添えて、以下のページよりご連絡ください。<br><a href=' + SBWEBINQUIREURL + '>'+SBWEBINQUIREURL+'</a>',
  errorSocialLogin:
    'ご指定の外部サービスアカウントはサッポロビールウェブサイト会員アカウントに連携されていないため、ログインできません。',
  errorNonSuid: 'サイトが一時的に混み合っております。申し訳ございませんが、時間をおいてから再度ログインをお願いいたします。'
};

// ソーシャルプラス認証リンク、
// provider変数をbaseUrlの後ろに追加するが必要
export const SOCIALAUTH = {
  baseUrl:
    ENV === 'prd'
      ? 'https://api.socialplus.jp/sapporo_ff/sapporobeer_live/'
      : 'https://api.socialplus.jp/sapporo_ff/sapporobeer_dev/',
  auth: '/authenticate?callback=',
  login: '/authenticate/login?callback=',
  registCallback: '/socialplus-callback/regist',
  loginCallback: '/socialplus-callback/login',
  registCampaignCallback: '/socialplus-callback/regist-campaign'
};

// myaccount
export const SUBSTATAS = {
  sub: '登録中',
  unsub: '未登録'
};
export const SNSLINKLABEL = {
  link: '登録する',
  unlink: '解除する'
};

// 公開用HTML
export const PUBLICHTML = {
  // 公開用HTMLフォルダ url
  url:
    ENV === 'prd'
      ? 'https://myaccount.sapporobeer.jp/'
      : 'https://dev-myaccount.sapporobeer.jp/',

  // メールマガジン登録変更
  updateMaga:
    ENV === 'prd'
      ? 'transition/member/23354_goib_2.html'
      : 'transition/member/23439_nrib_41.html',
  // 会員情報変更
  updateMember:
    ENV === 'prd'
      ? 'transition/member/23354_goib_37.html'
      : 'transition/member/23439_nrib_8.html',
  // メールアドレス変更画面
  updateMail:
    ENV === 'prd'
      ? 'transition/member/23354_goib_40.html'
      : 'transition/member/23439_nrib_5.html',
  // 新メールアドレス設定画面
  updateMailNew:
    ENV === 'prd'
      ? 'transition/member/23354_goib_41.html'
      : 'transition/member/23439_nrib_4.html',
};

// 退会画面
export const UNREGISTERROR =
  '退会のお手続きはすでに完了しています。';

export const SEXTYPE = ['男性', '女性', '回答しない'];
export const MAGATYPE = [
  'テキストのみのメール',
  '画像付き（HTML形式）のメール'
];

export const PREFECTURES = [
  null,
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県'
];
