import Vue from 'vue';
import newUrl from 'url';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import MyAccount from '../views/MyAccount.vue';
import Campaign from '../views/Campaign.vue';
import SocialPlusCallback from '../views/SocialPlusCallback.vue';
import SocialAuth from '../views/SocialAuth.vue';
import store from '../store';
import { PUBLICHTML, TITLE, CPLISTNOLOGIN } from '../common/const';

Vue.use(VueRouter);
/* eslint-disable no-unused-vars */
// ログイン画面にアクセスする時にurlパラメータとアクセスを確認し、遷移先を指定する
const ifNotAuthenticated = (to, from, next) => {
  if (to.query && to.query.url) {
    store.dispatch('setParams', to.query);
    // urlパラメータがあるかを確認する
    // アクセストークンがない場合はstoreにパラメータを格納し、ログイン画面へ遷移する
    if (!store.getters.accesstoken) {
      next();
      return;
    } else {
      // URLパラメータはサッポロドメイン内に限定する
      let parseUrl = newUrl.parse(to.query.url);
      if (parseUrl.host && parseUrl.host.slice(-15) != '.sapporobeer.jp') {
        window.location.assign('/');
      }
      // アクセストークンがある場合はurlに遷移する
      // urlの遷移先により、遷移方法を決める（SPA内部、公開用HTML、LINEキャンペーン）
      else if (!to.query.internalFlg || to.query.internalFlg === '1') {
        window.location.assign(to.query.url);
      }
      else {
        if (to.query.purposeFlg === 'campaign') {
          window.location.assign(PUBLICHTML.url + to.query.url);
        } // LINE・Twiiterキャンペーンのログイン後、suidと紐付け処理
        else if (
          to.query.purposeFlg === 'lineCampaign' ||
          to.query.purposeFlg === 'twitterCampaign'
        ) {
          let provider = '';
          if (to.query.purposeFlg === 'lineCampaign') {
            provider = 'line';
          }
          if (to.query.purposeFlg === 'twitterCampaign') {
            provider = 'twitter';
          }
          store.dispatch('campaignLogin', provider);
        }
        else {
          window.location.assign(to.query.url);
        }
      }
    }
    return;
  }

  // urlパラメータがない場合はマイアカウント画面へ遷移する
  next('/');
};
// アクセストークがない時はログイン画面へ遷移する
const ifAuthenticated = (to, from, next) => {
  if (store.getters.accesstoken) {
    next();
    return;
  }
  if (!to.query.purposeFlg) {
    to.query.purposeFlg = '';
  }
  if (!to.query.internalFlg) {
    to.query.internalFlg = 1;
  }
  if (
    to.query.purposeFlg === 'campaignList' &&
    to.path.indexOf('/campaign') === 0
  ) {
    window.location.assign(CPLISTNOLOGIN);
  }
  if (to.query.snsUrl) {
    next(
      '/login?internalFlg=' +
      to.query.internalFlg +
      '&url=' +
      to.path +
      '%3FsnsUrl=' +
      to.query.snsUrl +
      '%26purposeFlg=' +
      to.query.purposeFlg
    );
    return;
  }
  next(
    '/login?internalFlg=' +
    to.query.internalFlg +
    '&purposeFlg=' +
    to.query.purposeFlg +
    '&url=' +
    to.path
  );
};

const routes = [
  {
    path: '/',
    name: 'MyAccount/',
    component: MyAccount,
    beforeEnter: ifAuthenticated,
    meta: {
      title: TITLE.myaccount
    }
  },
  {
    path: '/login/',
    name: 'Login',
    component: Login,
    beforeEnter: ifNotAuthenticated,
    meta: {
      title: TITLE.login
    }
  },
  {
    path: '/campaign/',
    name: 'Campaign',
    component: Campaign,
    beforeEnter: ifAuthenticated,
    meta: {
      title: TITLE.campaign
    }
  },
  {
    path: '/socialplus-callback/:function',
    name: 'SocialPlusCallback',
    component: SocialPlusCallback
    // beforeEnter: ifAuthenticated
  },
  {
    path: '/logout/',
    name: 'Logout',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Logout.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      title: TITLE.logout
    }
    // pathToRegexpOptions: { strict: true }
  },
  {
    path: '/socialCancel/:provider',
    name: 'SocialCancel',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/SocialCancel.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      title: TITLE.socialcancel
    }
    // pathToRegexpOptions: { strict: true }
  },

  {
    path: '/unRegist/',
    name: 'UnRegist',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/UnRegist.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      title: TITLE.unregist
    }
    // pathToRegexpOptions: { strict: true }
  },

  {
    path: '/campaign/authentication/',
    name: 'SocialAuth',
    component: SocialAuth,
    beforeEnter: ifAuthenticated,
    meta: {
      title: TITLE.socailauth
    }
    // pathToRegexpOptions: { strict: true } 正規表現のコンパイルオプション
  },

  //   { path: '*', redirect: '/' }
  // 404エラー画面
  {
    name: 'notFound',
    path: '*',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/NotFound.vue'),
    meta: { title: TITLE.notfound }
  }
];

const router = new VueRouter({
  mode: 'history',
  //   base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

export default router;
