import { AUTHERROR } from '../../common/const';

const state = {
  loginMessage: '',
  socialMessage: ''
};

const getters = {
  loginMessage: (state) => state.loginMessage,
  socialMessage: (state) => state.socialMessage
};

//認証のエラーメッセージを指定する
const mutations = {
  updateLoginMessage(state, errorCode) {
    switch (errorCode) {
      case 70:
        state.loginMessage = {
          title: AUTHERROR.errorNonSuid,
          message: ''
        };
        break;
      case 403:
        state.loginMessage = {
          title: AUTHERROR.errorMessageAuth,
          message: ''
        };
        break;
      case 404:
        state.loginMessage = {
          title: AUTHERROR.errorMessageAuth,
          message: ''
        };
        break;
      case 500:
        state.loginMessage = {
          title: AUTHERROR.errorSever,
          message: ''
        };
        break;
      case 'timeout':
        state.loginMessage = {
          title: AUTHERROR.errorSession,
          message: ''
        };
        break;
      case 'noEmail':
        state.loginMessage = {
          title: AUTHERROR.errorMessageEmail,
          message: ''
        };
        break;
      case 'noPassword':
        state.loginMessage = {
          title: AUTHERROR.errorMessagePassword,
          message: ''
        };
        break;
      case 'socialPlusError':
        state.loginMessage = {
          title: AUTHERROR.errorSocialPlus,
          message: ''
        };
        break;
      case 'socialPlusExist':
        state.loginMessage = {
          title: AUTHERROR.errorSocialExist,
          message: ''
        };
        break;
      case 'sever':
        state.loginMessage = {
          title: AUTHERROR.errorSever,
          message: ''
        };
        break;

      case '':
        state.loginMessage = {
          title: '',
          message: ''
        };
        break;
      default:
        break;
    }
  },

  //ソーシャルプラス連携のエラーメッセージを指定する
  updateSocialMessage(state, errorCode) {
    switch (errorCode) {
      case 'notfound':
        state.socialMessage = AUTHERROR.errorNotFound;
        break;
      case 'login':
        state.socialMessage = AUTHERROR.errorSocialLogin;
        break;
      case 'exist':
        state.socialMessage = AUTHERROR.errorSocialExist;
        break;
      case 'link':
        state.socialMessage = AUTHERROR.errorSocialLink;
        break;
      case 'nolink':
        state.socialMessage = AUTHERROR.errorNolink;
        break;
      case 'sever':
        state.socialMessage = AUTHERROR.errorSocialPlus;
        break;
      case '':
        state.socialMessage = '';
        break;
      default:
        state.socialMessage = AUTHERROR.errorSocialPlus;
        break;
    }
  }
};

const actions = {
  setLoginMessage({ commit }, error) {
    commit('updateLoginMessage', error);
  },
  setSocialMessage({ commit }, error) {
    commit('updateSocialMessage', error);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
