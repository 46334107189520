<!-- ソーシャルプラスcallback用ページ -->
<template>
  <div></div>
</template>

<script>
import newUrl from 'url';
import axiosAuth from '../services/axiosAuth';
import { PUBLICHTML, MAINTAINURL, OVERLOADURL } from '../common/const';
export default {
  /* eslint-disable no-unused-vars, no-unreachable*/
  data() {
    return {
      originalUrl: '/',
      urlParams: '',
    };
  },
  computed: {
    //認証のJWTを取得
    accesstoken() {
      return this.$store.getters.accesstoken;
    },
    status() {
      return this.$route.query.status;
    },
    token() {
      return this.$route.query.token;
    },
    memberinfo() {
      return this.$store.getters.memberinfo;
    }
  },
  created() {
    //URLパラメータのあるかを確認する
    if (this.$route.query.params) {
      let params = this.$route.query.params.split('|||');

      if (params.length === 5 && params[3] === 'campaign') {
        this.$store.dispatch('setParams', {
          url: params[0],
          internalFlg: params[1],
          purposeFlg: params[2]
        });
        this.originalUrl = params[4] + '?status=unauthorized';
      } else {
        this.$store.dispatch('setParams', {
          url: params[0],
          internalFlg: params[1],
          purposeFlg: params[2]
        });
        this.urlParams =
          '/login?purposeFlg=' +
          params[2] +
          '&internalFlg=' +
          params[1] +
          '&url=' +
          params[0];
      }
    }

    this.$store.dispatch('setSocialMessage', '');
    //ソーシャルプラスSNS認証成功した場合、APIに該当処理する
    if (this.$route.query.status === 'authorized') {
      switch (this.$route.params.function) {
        // ソーシャルプラスにSUIDがない場合、紐付け処理
        case 'regist':
          this.regist();
          break;
        // 紐付けたユーザーを自動ログイン処理
        case 'login':
          //ソーシャルログインリンクを悪用防止のため、会員のuser-agentを確認する
          this.socialLogin();
          break;
        //LINE、Twitterキャンペーンログイン時連携処理
        case 'regist-campaign':
          this.registCampaign();
          break;
        default:
          this.$router.push('/');
          break;
      }
    } else if (
      //SNSのサッポロ認証失敗、キャンセルした場合
      this.$route.query.status === 'unauthorized' &&
      this.$route.query.reason === 'Unauthorized.'
    ) {
      //マイアカウント画面で外部連携を実施した場合
      if (this.$route.params.function === 'regist') {
        this.$store.dispatch('setSocialMessage', 'link');
        this.$router.push('/');
      }
      //LINEキャンペーン・Twitterキャンペーンでの連携時に認証をキャンセルした場合、
      //パラメータにクエリを渡してSNSキャンペーン認証画面へ遷移する
      else if (this.$route.params.function === 'regist-campaign') {
        let url = '';
        url =
          '?internalFlg=' +
          this.$store.getters.loginParams.internalFlg +
          '&snsUrl=' +
          this.$store.getters.loginParams.url +
          '&purposeFlg=' +
          this.$store.getters.loginParams.purposeFlg;
        this.$router.push('/campaign/authentication'+ url);
      }
       else {
        if (this.urlParams != '') {
          this.$store.dispatch('logout');
          this.$store.dispatch('setLoginMessage', 'socialPlusError');
          this.$router.push(this.urlParams);
        } else {
          window.location.assign(this.originalUrl);
        }
      }
    }
    //追加連携時、別のアカウントと紐付け済みの場合
    else if (
      this.$route.query.status === 'failed' &&
      this.$route.query.reason ===
        'This social media has already been associated with another user.'
    ) {
      //マイアカウント画面へ遷移する      
      this.$store.dispatch('setSocialMessage', 'exist');
      this.$router.push('/');   
    }
    //該当ユーザ情報が見つからない場合
    else if (
      this.$route.query.status === 'failed' &&
      this.$route.query.reason === 'Specified user not found.' &&
      this.$route.params.function != 'login'
    ) {
      this.$store.dispatch('setSocialMessage', 'notfound');
      this.$router.push('/');
    }
    //ソーシャルログインが失敗した場合
    else if (
      this.$route.query.status === 'failed' &&
      this.$route.params.function === 'login'
    ) {
      this.$store.dispatch('setSocialMessage', 'login');
      let url = '';
      if (this.$store.getters.loginParams.url) {
        url =
          '?internalFlg=' +
          this.$store.getters.loginParams.internalFlg +
          '&url=' +
          this.$store.getters.loginParams.url +
          '&purposeFlg=' +
          this.$store.getters.loginParams.purposeFlg;
      }
      this.$router.push('/login' + url);
    } else {
      this.$store.dispatch('setSocialMessage', 'sever');
      this.$router.push('/');
    }
  },
  methods: {
    //新規連携処理
    regist() {
      axiosAuth
        .post('/social/regist', {
          token: this.$route.query.token,
          accesstoken: this.accesstoken
        })
        .then((response) => {
          window.location.assign('/');
        })
        .catch((error) => {
          if (!error.response) {
            this.$store.dispatch('setSocialMessage', 'sever');
          } else {
            if (error.response.status === 503) {
              window.location.assign(MAINTAINURL);
            }
            if (error.response.status === 403) {
              this.$store.dispatch('setSocialMessage', 'exist');
            } else {
              this.$store.dispatch('setSocialMessage', 'link');
            }
          }
          this.$router.push('/');
        });
    },
    //ソーシャルログイン処理
    socialLogin() {
      axiosAuth
        .post('/social/login', {
          token: this.$route.query.token
        })
        .then((response) => {
          let responseObj = JSON.parse(response.data.body);
          responseObj.autoLogin = true;

          this.$store.dispatch('setAuthData', responseObj);
          // storeにurlパラメータが存在するかを確認する
          if (this.$store.getters.loginParams.url) {
            let url = this.$store.getters.loginParams.url;
            let purposeFlg = this.$store.getters.loginParams.purposeFlg;
            let internalFlg = this.$store.getters.loginParams.internalFlg;
            this.$store.dispatch('setParams', {
              url: '',
              purposeFlg: '',
              internalFlg: ''
            });
            // URLパラメータはサッポロドメイン内に限定する
            let parseUrl = newUrl.parse(url);
            if (parseUrl.host && parseUrl.host.slice(-15) != '.sapporobeer.jp') {
              window.location.assign('/');
            // メールアドレスとパスワード保存チェックしない場合window.locationでstoreのアクセストークンが削除される
            } else if (internalFlg === '' || internalFlg === '1') {
              window.location.assign(url);
            } else {
              if (purposeFlg === 'campaign') {
                window.location.assign(PUBLICHTML.url + url);
              } else if (purposeFlg === 'lineCampaign') {
                window.location.assign(PUBLICHTML.url + url);
              } else if (purposeFlg === 'twitterCampaign') {
                window.location.assign(PUBLICHTML.url + url);
              } else {
                if (url.indexOf('transition') === 0) {
                  window.location.assign(PUBLICHTML.url + url);
                } else {
                  if (!parseUrl.host) {
                    window.location.assign(PUBLICHTML.url + url);
                  } else {
                    window.location.assign(url);
                  }
                }
              }
            }
          } else {
            window.location.assign('/');
          }
        })
        .catch((error) => {
          if (!error.response) {
            this.$store.dispatch('setSocialMessage', 'sever');
            this.$router.push('/');
          }
          if (error.response.status === 503) {
            window.location.assign(MAINTAINURL);
            return;
          }
          if (error.response.status === 429) {
            window.location.assign(OVERLOADURL);
            return;
          }
          if (error.response.status === 404 && error.response.data === 70) {
            this.$store.dispatch('setLoginMessage', error.response.data);
          }
          else {
            this.$store.dispatch('setSocialMessage', 'login');
          }
          let url = '';
          if (this.$store.getters.loginParams.url) {
            url =
              '?internalFlg=' +
              this.$store.getters.loginParams.internalFlg +
              '&url=' +
              this.$store.getters.loginParams.url +
              '&purposeFlg=' +
              this.$store.getters.loginParams.purposeFlg;
          }
          window.location.assign('/login' + url);
        });
    },
    //LINE、Twitterキャンペーンログイン時連携処理
    registCampaign() {
      axiosAuth
        .post('/social/regist', {
          token: this.$route.query.token,
          accesstoken: this.accesstoken
        })
        .then((response) => {
          //連携成功した場合は、urlパラメータを整形し、遷移する
          if (this.$store.getters.loginParams.url) {
            let url = this.$store.getters.loginParams.url;
            let purposeFlg = this.$store.getters.loginParams.purposeFlg;
            let internalFlg = this.$store.getters.loginParams.internalFlg;
            this.$store.dispatch('setParams', {
              url: '',
              purposeFlg: '',
              internalFlg: ''
            });
            // URLパラメータはサッポロドメイン内に限定する
            let parseUrl = newUrl.parse(url);
            if (parseUrl.host && parseUrl.host.slice(-15) != '.sapporobeer.jp') {
              window.location.assign('/');
            } else if (internalFlg === '' || internalFlg === '1') {
              window.location.assign(url);
            } else {
              if (purposeFlg === 'campaign') {
                window.location.assign(PUBLICHTML.url + url);
              } else if (purposeFlg === 'lineCampaign') {
                window.location.assign(PUBLICHTML.url + url);
              } else if (purposeFlg === 'twitterCampaign') {
                window.location.assign(PUBLICHTML.url + url);
              } else {
                if (url.indexOf('transition') === 0) {
                  window.location.assign(PUBLICHTML.url + url);
                } else {
                  if (!parseUrl.host) {
                    window.location.assign(PUBLICHTML.url + url);
                  } else {
                    window.location.assign(url);
                  }
                }
              }
            }
          } else {
            window.location.assign('/');
          }
        })
        .catch((error) => {
          if (!error.response) {
            this.$store.dispatch('setSocialMessage', 'sever');
            this.$router.push('/');
          }
          if (error.response.status === 503) {
            window.location.assign(MAINTAINURL);
          }
          //連携に失敗した場合は、マイアカウント画面へ遷移し、エラーメッセージを表示する
          if (!error.response) {
            //エラーが発生した場合
            this.$store.dispatch('setSocialMessage', 'sever');
          } else {
            if (error.response.status === 503) {
              window.location.assign(MAINTAINURL);
            }
            if (error.response.status === 403) {
              //既に会員情報と紐づいているアカウントで連携を行った場合
              this.$store.dispatch('setSocialMessage', 'exist');
            } else {
              this.$store.dispatch('setSocialMessage', 'link');
            }
          }
          this.$router.push('/');
        });
    }
  }
};
</script>
