<template>
  <div id="app">
    <PasswordResetModal
      @close="closeModal"
      @reset="resetPassword"
      v-if="showModal"
    ></PasswordResetModal>
    <router-view />
  </div>
</template>

<script>
import PasswordResetModal from './components/PasswordResetModal';
import { PASSRESETURL } from './common/const';
export default {
  components: { PasswordResetModal },
  data() {
    return {
      //パスワード再設定のモーダルの初期表示判定 true:表示 false:非表示
      showModal: false,
      PASSRESETURL: PASSRESETURL
    };
  },
  methods: {
    resetPassword() {
      this.showModal = false;
      localStorage.setItem('modalStatus', true);
      document.location.href = this.PASSRESETURL;
    },
    closeModal() {
      this.showModal = false;
      localStorage.setItem('modalStatus', true);
    }
  },
  beforeMount() {
    //初回訪問の判定
    let checkModalStatus = localStorage.getItem('modalStatus');
    if (checkModalStatus) {
      this.showModal = false;
    }
  }
};
</script>

<style></style>
