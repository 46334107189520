import Vue from 'vue';
import Vuex from 'vuex';

import memberinfo from './modules/memberInfo';
import auth from './modules/auth';
import loginMessages from './modules/loginMessages';
import loginParams from './modules/loginParams';

Vue.use(Vuex);

// vuexのstore定義
export default new Vuex.Store({
  modules: {
    memberinfo,
    auth,
    loginMessages,
    loginParams
  }
});
