<!-- マイアカウント画面 -->
<template>
  <!-- サッポロビール共通ヘッダー -->
  <HeaderSapporo>
    <main class="layout-content">
      <header class="page-title">
        <h1>マイアカウント</h1>
      </header>

      <div class="layout-main-content">
        <div class="section">
          <div class="member-head">
            <p class="member-head_name">
              <b>{{ this.memberinfo.namesei }}{{ this.memberinfo.namemei }}</b>
              様
            </p>
            <div class="member-head_logout-button">
              <p class="m-button opt-logout-button">
                <router-link to="/logout/">ログアウト</router-link>
              </p>
            </div>
          </div>
        </div>
        <!-- /.section -->

        <section class="section">
          <div class="section-title">
            <h2>キャンペーンに応募する</h2>
          </div>
          <div class="m-color-box theme-gray">
            <div class="floating-button">
              <div class="floating-button_content">
                <p>
                  ウェブサイトからのキャンペーン応募はサッポロビール会員限定！お得なキャンペーンに今すぐ応募しよう
                </p>
              </div>
              <div class="floating-button_button">
                <p class="m-button opt-alpha">
                  <a href="/campaign">
                    キャンペーン一覧を見る
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
        <!-- /.section -->

        <section class="section">
          <div class="section-title">
            <h2>お客様情報</h2>
          </div>
          <ul class="m-link-list-horizontal opt-default-style">
            <li>
              <a :href="PUBLICHTML.url + PUBLICHTML.updateMember"
                >お客様情報を確認・変更する</a
              >
            </li>
            <li>
              <a :href="PUBLICHTML.url + PUBLICHTML.updateMail"
                >メールアドレスを変更する</a
              >
            </li>
            <li>
              <a :href="PASSRESETURL">パスワードを変更する(お忘れの方はこちら)</a>
            </li>
            <li>
              <router-link to="/unRegist">退会する</router-link>
            </li>
          </ul>
          <div class="member-information">
            <div class="member-information_content">
              <section class="m-color-box theme-gray js-equal-heights">
                <h3 class="m-color-box_title">
                  <strong>メールマガジン登録状況</strong>
                  <a
                    :href="PUBLICHTML.url + PUBLICHTML.updateMaga"
                    class="float-right weight-normal size-medium"
                    >変更する</a
                  >
                </h3>
                <div class="m-registered-list">
                  <ul>
                    <li>
                      <span
                        :class="checkSubscribeClass(this.memberinfo.mailmaga1)"
                        >{{ checkSubscribe(this.memberinfo.mailmaga1) }}</span
                      >SAPPORO NET NEWS
                    </li>
                    <li>
                      <span
                        :class="checkSubscribeClass(this.memberinfo.mailmaga2)"
                        >{{ checkSubscribe(this.memberinfo.mailmaga2) }}</span
                      >YEBISUプレミアム通信
                    </li>
                    <li>
                      <span
                        :class="checkSubscribeClass(this.memberinfo.mailmaga3)"
                        >{{ checkSubscribe(this.memberinfo.mailmaga3) }}</span
                      >焼酎＆梅酒だより
                    </li>
                    <li>
                      <span
                        :class="checkSubscribeClass(this.memberinfo.mailmaga4)"
                        >{{ checkSubscribe(this.memberinfo.mailmaga4) }}</span
                      >GRANDE POLAIRE Precious Salon
                    </li>
                  </ul>
                </div>
              </section>
            </div>
            <div class="member-information_content">
              <section class="m-color-box theme-gray js-equal-heights">
                <form action name="socialform" method="POST" class="validation">
                  <h3 class="m-color-box_title">
                    <strong>外部アカウント連携状況</strong>
                  </h3>
                  <p>
                    登録したアカウントを使ってサッポロビールのマイアカウントにログインします。
                    <br />
                    <a
                      href="https://www.sapporobeer.jp/social/sociallogin.html"
                      target="_blank"
                      >外部サービスアカウント連携利用規約</a
                    >
                  </p>
                  <p
                    style="word-break:break-all"
                    v-html="socialMessage"
                    v-if="socialMessage != ''"
                    id="socialErrorMsg"
                    class="m-form_error-text"
                  >
                    {{ socialMessage }}
                  </p>
                  <div
                    class="m-registered-list opt-col2"
                    v-if="!socialPlusError"
                  >
                    <ul id="social_action_link">
                      <li name="facebook">
                        <span
                          name="btn"
                          :class="checkSubscribeClass(this.providers.facebook)"
                          >{{ checkSubscribe(this.providers.facebook) }}</span
                        >
                        Facebook
                        <template v-if="this.providers.facebook != '1'">
                          <a href="#" @click.prevent="link('facebook')">{{
                            SNSLINKLABEL.link
                          }}</a>
                        </template>
                        <template v-else>
                          <router-link to="/socialCancel/facebook">{{
                            SNSLINKLABEL.unlink
                          }}</router-link>
                        </template>
                      </li>
                      <li name="twitter">
                        <span
                          name="btn"
                          :class="checkSubscribeClass(this.providers.twitter)"
                          >{{ checkSubscribe(this.providers.twitter) }}</span
                        >
                        X
                      </li>
                      <li name="google">
                        <span
                          name="btn"
                          :class="checkSubscribeClass(this.providers.google)"
                          >{{ checkSubscribe(this.providers.google) }}</span
                        >Google
                        <template v-if="this.providers.google != '1'">
                          <a href="#" @click.prevent="link('google')">{{
                            SNSLINKLABEL.link
                          }}</a>
                        </template>
                        <template v-else>
                          <router-link to="/socialCancel/google">{{
                            SNSLINKLABEL.unlink
                          }}</router-link>
                        </template>
                      </li>
                      <li name="line">
                        <span
                          name="btn"
                          :class="checkSubscribeClass(this.providers.line)"
                          >{{ checkSubscribe(this.providers.line) }}</span
                        >LINE
                        <template v-if="this.providers.line != '1'">
                          <a href="#" @click.prevent="link('line')">{{
                            SNSLINKLABEL.link
                          }}</a>
                        </template>
                        <template v-else>
                          <router-link to="/socialCancel/line">{{
                            SNSLINKLABEL.unlink
                          }}</router-link>
                        </template>
                      </li>
                      <li name="yahoo">
                        <span
                          name="btn"
                          :class="checkSubscribeClass(this.providers.yahoo)"
                          >{{ checkSubscribe(this.providers.yahoo) }}</span
                        >Yahoo! JAPAN ID
                        <template v-if="this.providers.yahoo != '1'">
                          <a href="#" @click.prevent="link('yahoo')">{{
                            SNSLINKLABEL.link
                          }}</a>
                        </template>
                        <template v-else>
                          <router-link to="/socialCancel/yahoo">{{
                            SNSLINKLABEL.unlink
                          }}</router-link>
                        </template>
                      </li>
                    </ul>
                  </div>
                  <p class="m-notes">
                    サッポロウェブサイト会員と各サービスのアカウントは1つずつしか連携できません。
                  </p>
                  <p style="color:#e51b33; margin-top:0;" class="m-notes">
                    現在、Xアカウントによるマイアカウントログインはご利用いただけません。
                  </p>
                </form>
              </section>
            </div>
          </div>
        </section>
        <!-- /.section -->
        <section class="section">
          <div class="m-color-box theme-gray">
            <h2 class="m-color-box_title">
              <strong>ご利用にあたって</strong>
            </h2>
            <ul class="m-link-list-horizontal opt-default-style">
              <li>
                <a href="https://www.sapporobeer.jp/support/">よくあるご質問</a>
              </li>
              <li>
                <a href="https://www.sapporobeer.jp/membership/">会員規約</a>
              </li>
            </ul>
          </div>
        </section>
        <!-- /.section -->
        <!-- /.layout-main-content -->
      </div>
      <!-- /.layout-content -->
    </main>
  </HeaderSapporo>
</template>

<script>
import {
  SOCIALAUTH,
  SUBSTATAS,
  SNSLINKLABEL,
  PUBLICHTML,
  MAINTAINURL,
  PASSRESETURL,
} from '../common/const';
import axiosAuth from '../services/axiosAuth';
import HeaderSapporo from '../components/HeaderSapporo';

export default {
  components: {
    HeaderSapporo
  },
  data() {
    return {
      socialPlusError: false,
      // ソーシャルプラスSNS "1": 登録中, "2": 未登録
      providers: {
        facebook: 2,
        google: 2,
        yahoo: 2,
        twitter: 2,
        line: 2
      },
      SNSLINKLABEL: SNSLINKLABEL,
      PUBLICHTML: PUBLICHTML,
      PASSRESETURL: PASSRESETURL
    };
  },
  computed: {
    //認証のJWTを取得
    accesstoken() {
      return this.$store.getters.accesstoken;
    },
    //会員情報を取得
    memberinfo() {
      return this.$store.getters.memberinfo;
    },
    //ソーシャルプラスエラーメッセージ
    socialMessage() {
      return this.$store.getters.socialMessage;
    }
  },
  methods: {
    //メルマガ登録状況を文字に変更
    checkSubscribe(sub) {
      if (sub === 1) {
        return SUBSTATAS.sub;
      } else if (sub === 2) {
        return SUBSTATAS.unsub;
      }
    },
    //メルマガ登録状況のcss class
    checkSubscribeClass(sub) {
      if (sub === 1) {
        return 'is-registered';
      } else if (sub === 2) {
        return 'is-not-registered';
      }
    },

    //ソーシャルプラスのSNS連携状況を取得する
    async checkProviders() {
      await axiosAuth
        .post('/social/providers', { accesstoken: this.accesstoken })
        .then((response) => {
          if (response.data.status === 'ok') {
            for (
              let index = 0;
              index < response.data.providers.length;
              index++
            ) {
              switch (response.data.providers[index]) {
                case 'facebook':
                  this.providers.facebook = 1;
                  break;
                case 'google':
                  this.providers.google = 1;
                  break;
                case 'yahoo':
                  this.providers.yahoo = 1;
                  break;
                case 'twitter':
                  this.providers.twitter = 1;
                  break;
                case 'line':
                  this.providers.line = 1;
                  break;
              }
            }
          }
        })
        .catch((error) => {
          if (!error.response || error.response.status != 404) {
            this.socialPlusError = true;
            this.$store.dispatch('setSocialMessage', 'sever');
            if (error.response && error.response.status === 503) {
              window.location.assign(MAINTAINURL);
            }
          }
        });
    },
    //ソーシャルプラス連携
    link(provider) {
      let href =
        SOCIALAUTH.baseUrl +
        provider +
        SOCIALAUTH.auth +
        window.location.origin +
        SOCIALAUTH.registCallback;
      window.location.replace(href);
    }
  },
  beforeMount() {
    //ページタイトルを設定
    document.title = this.$route.meta.title;
  },
  created() {
    this.checkProviders();
  }
};
</script>

<style scoped>
/* ==========================================================================
   Member index
   ========================================================================== */
/* Member head
   ========================================================================== */
@media (min-width: 769px) {
  .member-head {
    display: table;
    width: 100%;
  }
}
.member-head_name {
  display: table-cell;
  font-size: 2.8em;
  font-weight: bold;
  vertical-align: middle;
  line-height: 1.2;
}
@media (max-width: 768px) {
  .member-head_name {
    display: block;
    font-size: 1.8em;
  }
}
.member-head_name > b {
  font-size: 1.286em;
}
@media (max-width: 768px) {
  .member-head_name > b {
    font-size: 1.333em;
  }
}
.member-head_logout-button {
  display: table-cell;
  width: 144px;
  vertical-align: middle;
}
@media (max-width: 768px) {
  .member-head_logout-button {
    margin-top: 30px;
    display: block;
    width: 108px;
  }
  .member-head_logout-button:first-child {
    margin-top: 0;
  }
}
@media (max-width: 768px) and (max-width: 768px) {
  .member-head_logout-button {
    margin-top: 20px;
  }
}
/* Floating button
   ========================================================================== */
@media (min-width: 769px) {
  .floating-button {
    display: table;
    width: 100%;
  }
}
.floating-button_content {
  vertical-align: middle;
}
@media (min-width: 769px) {
  .floating-button_content {
    display: table-cell;
    padding-right: 25px;
  }
}
.floating-button_button {
  width: 285px;
  max-width: 100%;
}
@media (min-width: 769px) {
  .floating-button_button {
    display: table-cell;
    vertical-align: middle;
  }
}
@media (max-width: 768px) {
  .floating-button_button {
    margin: 0 auto;
    margin-top: 30px;
  }
  .floating-button_button:first-child {
    margin-top: 0;
  }
}
@media (max-width: 768px) and (max-width: 768px) {
  .floating-button_button {
    margin-top: 20px;
  }
}
/* Member information
   ========================================================================== */
.member-information {
  margin-top: 40px;
  display: table;
  width: 100%;
}
.member-information:first-child {
  margin-top: 0;
}
@media (max-width: 768px) {
  .member-information {
    margin-top: 30px;
  }
}
.member-information_content {
  margin-top: 30px;
}
.member-information_content:first-child {
  margin-top: 0;
}
@media (max-width: 768px) {
  .member-information_content {
    margin-top: 20px;
  }
}
@media (min-width: 769px) {
  .member-information_content {
    display: table-cell;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .member-information_content:first-child {
    width: 419px;
    max-width: 37.5%;
    padding-right: 32px;
  }
  .member-information_content:nth-child(2) {
    margin-top: 0;
    max-width: 62.5%;
  }
}
</style>
