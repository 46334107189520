import axios from 'axios';
import { APIURL } from '../common/const';

const instance = axios.create({
  // APIのBASE URLを定義する
  baseURL: APIURL,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  }
});

export default instance;
