<!--SNS連携キャンペーン認証画面 -->
<template>
  <!-- リンクのない共通ヘッダー -->
  <HeaderNolink>
    <main class="layout-content">
      <header class="page-title">
        <h1>マイアカウント</h1>
      </header>
      <div class="msg-area">
        <div class="msg">
          以下の外部サービスアカウントを連携してからご応募ください。
          <h2 class="login-column_title">
            <div v-if="campaignProvider === 'lineCampaign'">
              <a href="#" @click.prevent="link('line')">
                <img
                  src="/common/img/parts/sns-auth-btn_base_line.png"
                  alt="LINEでログイン"
                />
                <img
                  src="/common/img/parts/sns-auth-btn_hover_line.png"
                  alt="LINEでログイン"
                  class="active"
                />
              </a>
            </div>
            <!--
            <div v-else-if="campaignProvider === 'twitterCampaign'">
                  <a href="#" @click.prevent="link('twitter')">
                Xでログイン
              </a>
              </div>
              <div v-else-if="campaignProvider === 'facebookCampaign'">
                <a href="#" @click.prevent="link('facebook')">
                facebookでログイン
              </a>
            </div>
             -->
            <div v-else v-on="checkProvider()"></div>
          </h2>
        </div>
      </div>
    </main>
  </HeaderNolink>
</template>

<script>
import { SOCIALAUTH } from "../common/const";
import HeaderNolink from "../components/HeaderNolink";
export default {
  components: {
    HeaderNolink,
  },

  computed: {
    //SNSキャンペーン種別を取得
    campaignProvider() {
      return this.$route.query.purposeFlg;
    },
    accesstoken() {
      return this.$store.getters.accesstoken;
    },
    // URLパラメータ
    loginParams() {
      return this.$store.getters.loginParams;
    },
  },

  methods: {
    //ソーシャルプラス連携
    link(provider) {
      let params =
        "?params=" +
        this.$route.query.snsUrl +
        "|||" +
        this.$route.query.internalFlg +
        "|||" +
        this.$route.query.purposeFlg;
      let href =
        SOCIALAUTH.baseUrl +
        provider +
        SOCIALAUTH.auth +
        window.location.origin +
        SOCIALAUTH.registCampaignCallback +
        params;
      window.location.replace(href);
    },
    checkProvider() {
      if (!(this.$route.query.purposeFlg === "lineCampaign")) {
        //クエリにプロバイダが指定されていない場合、マイアカウント画面へ遷移し、エラーメッセージを表示する
        this.$store.dispatch("setSocialMessage", "sever");
        this.$router.push("/");
      }
      return;
    }
  }
};
</script>

<style scoped>
.layout-content {
  background-color: #f5f6f9;
}
.msg-area {
  background-color: #f5f6f9;
  padding-left: 6%;
  padding-right: 6%;
  margin-bottom: 70px;
}
.msg {
  background-color: #ffffff;
  color: #ff0000;
  font-size: 28px;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}
.login-column_title {
  position: relative;
  text-align: center;
  margin-top: 50px;
}
@media only screen and (max-width: 769px) {
  th {
    width: 94.5%;
  }
  td > ul {
    margin: 0;
    padding: 0.7em 10px;
    list-style: disc;
  }
  .msg-area {
    padding-left: 30px;
    padding-right: 30px;
  }
  .msg {
    padding-left: 6%;
    padding-right: 6%;
  }
  .login-column_title .active {
    position: absolute;
    opacity: 0;
  }
  .login-column_title:hover .active {
    position: absolute;
    opacity: 1;
    transform: translate(-100%, 0%);
  }
}
@media only screen and (min-width: 769px) {
  .msg {
    width: 800px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .login-column_title .active {
    position: absolute;
    opacity: 0;
    top: 0%;
    left: 37.9%;
  }
  .login-column_title a:hover .active {
    position: absolute;
    opacity: 1;
    top: 0%;
    left: 37.9%;
  }
}
</style>
