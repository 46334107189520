<!-- サッポロビール共通ヘッダー、public/index.htmlにてjqueryでインポートする -->
<template>
  <div class="layout-container" id="return-top">
    <div id="default-header"></div>
    <slot />
    <div id="default-footer"></div>
  </div>
</template>

<script>
export default {
  props: [],
  methods: {}
};
</script>

<style scoped>
.member-head_name > b {
  word-break: break-all;
}
</style>