<!-- ログイン画面 -->
<template>
  <!-- 動的に共通ヘッダーをインポートする -->
  <component :is="headerComponent" :data="data">
    <main class="layout-content">
      <header class="page-title">
        <h1>マイアカウント ログイン</h1>
      </header>

      <div class="layout-main-content">
        <div class="section">
          <p class="regist-explanation">
            サッポロビールウェブサイトでのキャンペーンご応募など、サービスご利用には会員登録が必要となります。
            <br />1度ご登録いただくとマイアカウントから、次回以降のキャンペーンご応募が簡単になります。
          </p>
          <div class="login-column" :style="loginStyle">
            <div class="login-column_content">
              <form
                method="post"
                action="index.html"
                class="m-form"
                :style="loginStyle"
              >
                <h2 class="login-column_title">
                  メールアドレス・パスワードで
                  <br class="m-sp" />ログイン
                </h2>
                <p>
                  ※パスワードポリシーを変更しました。2020年10月31日以前に設定をしたパスワードではログインができません。
                  <a :href="PASSRESETURL">
                    こちらのページ
                  </a>
                  よりパスワードの変更をお願いします。
                </p>
                <template v-if="this.loginMessage != ''">
                  <p class="color-strong" :class="{loginMessage : this.loginButtonDisabled}">
                    <em v-html="loginMessage.title" style="word-break:break-all"></em>
                  </p>
                  <div
                    class="m-error-list color-strong"
                    v-if="loginMessage.message != ''"
                  >
                    <ul>
                      <li>{{ loginMessage.message }}</li>
                    </ul>
                  </div>
                  <!-- m-error-list -->
                </template>
                <p class="m-form_input">
                  <input
                    id="email"
                    type="email"
                    placeholder="メールアドレス"
                    name="ml"
                    maxlength="255"
                    value
                    v-model.trim="email"
                  />
                </p>
                <p class="m-form_input space-near">
                  <input
                    id="password"
                    type="password"
                    placeholder="パスワード"
                    name="pw1"
                    maxlength="50"
                    value
                    v-model="password"
                  />
                </p>
                <p class="space-near">
                  <input
                    id="save"
                    type="checkbox"
                    name="save"
                    checked="checked"
                    v-model="autoLogin"
                  />
                  <label for="save">メールアドレス/パスワードを保存する</label>
                </p>
                <p class="m-button theme-emphasize opt-small opt-centering">
                  <button @click.prevent="login" :disabled="this.loginButtonDisabled">ログイン</button>
                </p>
                <p class="m-button opt-alpha opt-small opt-centering theme-gray pc-display-non">
                  <a :href="regUrl">新規登録</a>
                </p>
                <ul class="m-link-list opt-default-style">
                  <li>
                    <a href="https://www.sapporobeer.jp/membership/"
                      >会員規約</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://www.sapporobeer.jp/support/customer/faq/0000000257/"
                      >ログインができない場合</a
                    >
                  </li>
                  <li>
                    <a :href="PASSRESETURL + '?internalFlg=0'"
                      >パスワード再設定（パスワードをお忘れの方はこちら）</a
                    >
                  </li>
                </ul>
              </form>
              <!-- /.m-form -->
            </div>
            <div
              class="login-column_content"
              v-if="!lineCampaign && !twitterCampaign"
            >
              <SocialLogin />
            </div>
          </div>
        </div>
        <!-- /.section -->

        <section
          :class="{ 'sp-display-non': lineCampaign || twitterCampaign }"
          class="section"
        >
          <div class="m-color-box theme-gray">
            <p class="m-button opt-alpha opt-small opt-centering">
              <a :href="regUrl">新規登録</a>
            </p>
          </div>
        </section>
        <!-- /.section -->
        <!-- /.layout-main-content -->
      </div>
      <!-- /.layout-content -->
    </main>
  </component>
</template>

<script>
import { REGURL, PASSRESETURL } from '../common/const';
import SocialLogin from '../components/SocialLogin';

export default {
  name: 'Login',
  props: ['data', 'type'],
  components: {
    SocialLogin
  },
  data() {
    return {
      email: '',
      password: '',
      autoLogin: true,
      headerComponent: null,
      PASSRESETURL: PASSRESETURL
    };
  },
  computed: {
    //認証のエラーメッセージ
    loginMessage() {
      return this.$store.getters.loginMessage;
    },
    loginButtonDisabled() {
      return this.$store.getters.loginButtonDisabled;
    },
    //会員登録URL,パラメータがある場合はurlを追加する
    regUrl() {
      let regUrl = REGURL;
      if (this.$store.getters.loginParams.purposeFlg) {
        regUrl =
          REGURL +
          '?url=' +
          this.$store.getters.loginParams.url +
          '&purposeFlg=' +
          this.$store.getters.loginParams.purposeFlg;
      }
      return regUrl;
    },
    lineCampaign() {
      if (this.$route.query.purposeFlg === 'lineCampaign') {
        return true;
      }
      return false;
    },
    twitterCampaign() {
      if (this.$route.query.purposeFlg === 'twitterCampaign') {
        return true;
      }
      return false;
    },
    //lineキャンペーンなどの用、リンクのない共通ヘッダー
    loader() {
      if (
        this.$route.query.purposeFlg === 'campaign' ||
        this.$route.query.purposeFlg === 'lineCampaign' ||
        this.$route.query.purposeFlg === 'twitterCampaign'
      ) {
        return () => import(`../components/HeaderNolink`);
      }
      return () => import(`../components/HeaderSapporo`);
    },
    //lineキャンペーンなどの用、ログインフォームstyleを調整
    loginStyle() {
      if (this.lineCampaign || this.twitterCampaign) {
        return 'text-align: center; margin: 0 auto;';
      }
      return '';
    }
  },
  methods: {
    //入力空欄確認
    checkInput() {
      if (this.email == '') {
        this.$store.dispatch('setLoginMessage', 'noEmail');
        return false;
      }
      if (this.password == '') {
        this.$store.dispatch('setLoginMessage', 'noPassword');
        return false;
      }
      return true;
    },
    //全角を半角に変換
    toHalfWidth(input) {
      return input.replace(/[！-～]/g, 
        function(s) { return String.fromCharCode(s.charCodeAt(0) - 0xFEE0) }
      )
    },
    //ログイン処理
    login() {
      if (this.checkInput() != true) {
        return;
      }
      this.email = this.toHalfWidth(this.email);
      //storeの認証処理を実行する
      this.$store.dispatch('login', {
        email: this.email,
        password: this.password,
        autoLogin: this.autoLogin
      });
    }
  },
  created() {
    //画面実行時該当の共通ヘッダーをインポート
    this.loader()
      .then(() => {
        this.headerComponent = () => this.loader();
      })
      .catch(() => {
        this.headerComponent = () => import('../components/HeaderSapporo');
      });
  },
  beforeMount() {
    //ページタイトルを設定
    document.title = this.$route.meta.title;
  }
};
</script>

<style scoped>
/* ==========================================================================
   Member login
   ========================================================================== */
/* Login column
   ========================================================================== */

.login-column {
  margin-top: 90px;
}
.login-column:first-child {
  margin-top: 0;
}
.login-column_title {
  font-size: 2.4em;
  text-align: center;
  line-height: 1.3;
}
.regist-explanation {
  text-align: center;
  margin-bottom: 50px;
}
@media (min-width: 769px) {
  .login-column {
    display: table;
  }
  .login-column_content {
    display: table-cell;
    width: 50%;
    padding: 0 65px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .login-column_content + .login-column_content {
    border-left: 1px solid #dbdce2;
  }
  .pc-display-non {
    display: none;
  }
}
@media (max-width: 768px) {
  .login-column {
    margin-top: 50px;
  }  
  .login-column_content {
    padding: 30px 0;
  }
  .login-column_content:first-child {
    padding-top: 0;
  }
  .login-column_content + .login-column_content {
    border-top: 1px solid #dbdce2;
  }
  .theme-gray {
    background-color: #f1f2f6;
  }
  .login-column_title {
    font-size: 2em;
  }
  .sp-display-non {
    display: none;
  }
}
.loginMessage {
    display: none;
}
</style>
