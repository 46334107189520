<!-- ログイン画面のソーシャルログインコンポネント -->
<template>
  <form name="socialform" class="validation">
    <h2 class="login-column_title">
      各種サービスのアカウントで
      <br class="m-sp" />ログイン
    </h2>
    <p>
      登録したアカウントを使ってサッポロビールのマイアカウントにログインします。
      <br />
      <a
        href="https://www.sapporobeer.jp/social/sociallogin.html"
        target="_blank"
        >外部サービスアカウント連携利用規約</a
      >
    </p>
    <p class="m-form_error-text" v-if="socialMessage != ''">
      <em> {{ socialMessage }} </em>
    </p>
    <div class="m-sns-large-btn opt-col2">
      <ul>
        <li class="has-facebook">
          <a href="#" @click.prevent="socialLogin('facebook')">Facebook</a>
        </li>
        <li class="has-twitter">
          <a>X</a>
        </li>
        <li class="has-google">
          <a href="#" @click.prevent="socialLogin('google')">Google</a>
        </li>
        <li class="has-line">
          <a href="#" @click.prevent="socialLogin('line')">LINE</a>
        </li>
        <li class="has-yahoo">
          <a href="#" @click.prevent="socialLogin('yahoo')">Yahoo! JAPAN ID</a>
        </li>
        <!-- <li class="has-mixi"><a href="#" onClick="sociallogin('mixi')">mixi</a></li> -->
      </ul>
    </div>
    <div style="font-size:1.5em; color:#e51b33; margin-top:30px;">※現在、Xアカウントによるマイアカウントログインはご利用いただけません。</div>
  </form>
</template>

<script>
import { SOCIALAUTH } from '../common/const';
export default {
  name: 'SocialLogin',

  computed: {
    accesstoken() {
      return this.$store.getters.accesstoken;
    },
    // ソーシャルプラスのエラーメッセージ
    socialMessage() {
      return this.$store.getters.socialMessage;
    },
    // URLパラメータ
    loginParams() {
      return this.$store.getters.loginParams;
    }
  },
  methods: {
    //SNSのproviderにより、ログインリンクを生成する
    socialLogin(provider) {
      let href = '';
      let params = '';
      //URLパラメータをソーシャルプラスのcallbackリンク用整形
      if (this.loginParams.url) {
        params =
          '?params=' +
          this.loginParams.url +
          '|||' +
          this.loginParams.internalFlg +
          '|||' +
          this.loginParams.purposeFlg;
      }
      //ソーシャルログイン用リンクURL
      href =
        SOCIALAUTH.baseUrl +
        provider +
        SOCIALAUTH.login +
        window.location.origin +
        SOCIALAUTH.loginCallback +
        params;
      window.location.replace(href);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* ==========================================================================
   Member login
   ========================================================================== */
/* Login column
   ========================================================================== */

.login-column {
  margin-top: 90px;
}
.login-column:first-child {
  margin-top: 0;
}
.login-column_title {
  font-size: 2.4em;
  text-align: center;
  line-height: 1.3;
}
@media (max-width: 768px) {
  .login-column {
    margin-top: 50px;
  }
}
@media (min-width: 769px) {
  .login-column {
    display: table;
  }
}
@media (min-width: 769px) {
  .login-column_content {
    display: table-cell;
    width: 50%;
    padding: 0 65px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .login-column_content + .login-column_content {
    border-left: 1px solid #dbdce2;
  }
}
@media (max-width: 768px) {
  .login-column_content {
    padding: 30px 0;
  }
  .login-column_content:first-child {
    padding-top: 0;
  }
  .login-column_content + .login-column_content {
    border-top: 1px solid #dbdce2;
  }
}

@media (max-width: 768px) {
  .login-column_title {
    font-size: 2em;
  }
}
</style>
