<!-- ログイン後キャンペーン画面 -->
<template>
  <!-- サッポロビール共通ヘッダー -->
  <HeaderSapporo>
    <main class="layout-content">
      <header class="m-page-header opt-transparent">
        <div class="m-section m-page-title">
          <h1>キャンペーン</h1>
        </div>
      </header>

      <div class="layout-main-content m-campaign_main">
        <div class="m-anchor-nav">
          <ul>
            <li 
              v-if="specialCampaignList.length"
            >
            <a href="#anchor-compaign-special">
              <span>あなただけの特別なキャンペーン</span>
            </a>
            </li>
            <li>
              <a href="#anchor-campaign-list">
                <span>キャンペーン一覧</span>
              </a>
            </li>
            <li>
              <a href="#anchor-campaign-area">
                <span>地域限定キャンペーン</span>
              </a>
            </li>
          </ul>
        </div>
        <b v-show="false"
          >{{ this.memberinfo.namesei }}{{ this.memberinfo.namemei }}様</b
        >
        <!-- 特別告知 -->
        <section
          v-if="specialCampaignList.length"
          class="m-full-content m-campaign"
          id="anchor-compaign-special"
        >
          <div class="m-section">
            <div class="m-section-title">
              <h2>あなただけの特別なキャンペーン</h2>
            </div>
            <div class="m-media theme-column opt-3column">
              <ul class="m-campaign_items">
                <li
                  v-for="specialCampaign in specialCampaignList"
                  :key="specialCampaign.title"
                >
                  <a :href="specialCampaign.link"
                    v-bind:target="specialCampaign.linkUrl == -1 ? '_blank' : false"
                    class="m-campaign_items-box">
                    <figure>
                      <div class="m-media_img">
                        <img :src="specialCampaign.imgURL" alt />
                      </div>
                    </figure>
                    <figcaption>
                      <dl class="m-campaign_item-limit">
                        <dt>締切</dt>
                          <dd>{{ specialCampaign.endDate }}</dd>
                      </dl>
                      <div v-html="specialCampaign.title" class="m-campaign_item-title"></div>
                      <div
                        style="white-space:pre-wrap; word-wrap:break-word;"
                        class="m-campaign_item-description">{{ specialCampaign.subtitle }}</div>
                      <div class="m-campaign_item-appli m-button"><span>{{ specialCampaign.campaignType }}</span></div>
                    </figcaption>
                  </a>
                </li>
              </ul>
            </div>
            <!-- .m-media.opt-3column -->
          </div>
          <!-- .section -->
        </section>
        <!-- .m-full-content.opt-bg -->
    
        <section
          class="m-full-content m-campaign"
          id="anchor-campaign-list"
        >
          <div class="m-section">
            <!-- event start -->
            <div class="m-section-title">
              <h2>キャンペーン一覧</h2>
            </div>
            <p>{{ campaignMsg }}</p>
            <div class="m-media theme-column opt-3column">
              <ul class="m-campaign_items">
                <li
                  v-for="campaign in campaignList"
                  :key="campaign.title"
                >
                  <a :href="campaign.link"
                    v-bind:target="campaign.linkUrl == -1 ? '_blank' : false"
                    class="m-campaign_items-box">
                    <figure>
                      <div class="m-media_img">
                        <img :src="campaign.imgURL" alt />
                      </div>
                    </figure>
                    <figcaption>
                      <dl class="m-campaign_item-limit">
                        <dt>締切</dt>
                        <dd>{{ campaign.endDate }}</dd>
                      </dl>
                      <div v-html="campaign.title" class="m-campaign_item-title"></div>
                      <div
                        style="white-space:pre-wrap; word-wrap:break-word;"
                        class="m-campaign_item-description">{{ campaign.subtitle }}</div>
                      <div class="m-campaign_item-appli m-button"><span>{{ campaign.campaignType }}</span></div>
                    </figcaption>
                  </a>
                </li>
              </ul>
            </div>
            <!-- .m-media.opt-3column -->
            <!-- event end -->
          </div>
          <!-- .section -->
        </section>

        <section
           class="m-full-content m-campaign m-campaign_area"
           id="anchor-campaign-area"
        >
          <div class="m-section">
            <div class="m-section-title">
              <h2>地域限定キャンペーン</h2>
              <div class="m-select opt-custom-select js-disabled-reset">
                  <select name="select-area" id="select-area" class="js-custom-select" data-placeholder="すべて">
                    <option class="js-area-tag" value="area-all" selected="selected">すべて</option>
                      <option class="js-area-tag"
                        v-for="area in areaClass"
                        :key="area.areaName"
                        v-bind:value="area.classArea"
                      >{{area.areaName}}
                      </option>
                  </select>
              </div>
            </div>
            <p>{{ areaCampaignMsg }}</p>
            <div class="m-media theme-column opt-3column m-campaign_area-list" data-area="area-all">
              <ul class="m-campaign_items">
                <li class="area-campaign"
                  v-for="areaCampaign in areaCampaignList"
                  :key="areaCampaign.title"
                  v-bind:class="areaCampaign.areaClass"
                >
                  <a :href="areaCampaign.link"
                   v-bind:target="areaCampaign.linkUrl == -1 ? '_blank' : false"
                   class="m-campaign_items-box">
                    <figure>
                      <div class="m-media_img"><img :src="areaCampaign.imgURL" alt /></div>
                    </figure>
                    <figcaption>
                      <div class="m-campaign_item-area">{{ areaCampaign.area }}</div>
                      <dl class="m-campaign_item-limit">
                        <dt>締切</dt>
                        <dd>{{ areaCampaign.endDate }}</dd>
                      </dl>
                      <div v-html="areaCampaign.title" class="m-campaign_item-title"></div>
                      <div
                        style="white-space:pre-wrap; word-wrap:break-word;"
                        class="m-campaign_item-description">{{ areaCampaign.subtitle }}</div>
                      <div class="m-campaign_item-appli m-button"><span>{{ areaCampaign.campaignType }}</span></div>
                    </figcaption>
                  </a>
                </li>
              </ul>
            </div>
            <!-- .m-media.opt-3column -->
            <!-- event end -->
          </div>
          <!-- .section -->
        </section>
        <!-- /.layout-main-content -->
      </div>
      <!-- .m-full-content.opt-bg -->
    </main>
  </HeaderSapporo>
</template>

<script>
//会員情報
// import axiosAuth from "../services/axiosAuth";
//キャンペーン情報
import axiosCampaign from '../services/axiosCampaign';
//定数情報
import * as CONST from '../common/const.js';

import HeaderSapporo from '../components/HeaderSapporo';

export default {
  components: {
    HeaderSapporo
  },
  data() {
    return {
      // キャンペーン情報 初期化
      campaignList: [],
      // 特別告知 初期化
      specialCampaignList: [],
      // 地域別キャンペーン 初期化
      areaCampaignList: [],
      // 応募期間終了後画像
      campaignOverImg: require('@/assets/img/campaign/campaign_end.png'),
      // キャンペーン一覧のデータがない場合の表示
      campaignMsg: '',
      // 地域限定キャンペーンのデータがない場合の表示
      areaCampaignMsg: '',
      areaClass: CONST.AREA_LIST,
    };
  },
  computed: {
    accesstoken() {
      return this.$store.getters.accesstoken;
    },
    memberinfo() {
      return this.$store.getters.memberinfo;
    }
  },
  
  methods: {
    // 読み取ったキャンペーン情報を会員状況に応じて表示する
    showCampaign(orgCampaignList) {
      for (var i = 0; i < orgCampaignList.length; i++) {
        //jsonの値を形式チェックする
        //関数の返り値がfalseだったら次の繰り返し処理を行う
        var cheakResult = jsonCheck();
        if ( cheakResult == false ) {
            continue;
        }

        //締切年月日変更
        //IEの場合、0埋めされていない"-"区切りの日付をnew DateするとInvalid Dateになるため"/"区切りにする
        var endDate = this.replaceDate(orgCampaignList[i].endDate);
        endDate = new Date(endDate);
        var wDay = endDate.getDay();
        var endYear = endDate.getFullYear();
        var endMonth = endDate.getMonth() + 1;
        var endDay = endDate.getDate();
        var today = new Date();
        var endTime = orgCampaignList[i].endTime;

        if (endTime == CONST.NOONTIME) {
          endTime = endTime + CONST.NOON;
        }

        //キャンペーン種別がハガキで応募の確認
        if (orgCampaignList[i].campaignType == CONST.POSTCARD) {
          orgCampaignList[i].endDate =
            endYear +
            CONST.YEAR +
            endMonth +
            CONST.MONTH +
            endDay +
            CONST.DAY +
            CONST.WEEKCHARS[wDay] +
            CONST.POSTMARK;
        } else {
          orgCampaignList[i].endDate =
            endYear +
            CONST.YEAR +
            endMonth +
            CONST.MONTH +
            endDay +
            CONST.DAY +
            CONST.WEEKCHARS[wDay] +
            endTime;
        }
        //リンクの判断（myaccountドメインであれば自画面遷移するため）
        orgCampaignList[i].linkUrl = orgCampaignList[i].link.indexOf('.sapporobeer.jp/');

        //応募済み確認（単一応募のみ）
        var campaignId = orgCampaignList[i].id;
        var applyCampaignId = this.memberinfo.campaignid.split(',');
        var applicationType = orgCampaignList[i].applicationType;
        if (
          applyCampaignId.includes(campaignId) &&
          applicationType == CONST.ONLYAPPLY
        ) {
          orgCampaignList[i].apply = true;
          //リンクも押下できなくする
          orgCampaignList[i].link = null;
        } else {
          orgCampaignList[i].apply = false;
        }
        //応募期間表示確認
        var endPublishedDateTime = new Date(
          orgCampaignList[i].endPublishedDate
        );
        var endPublishedTime = Number(
          orgCampaignList[i].endPublishedTime.toString().substr(0, 2)
        );
        endPublishedDateTime.setHours(endPublishedTime);
        var endDateTime = endDate;
        endDateTime.setHours(Number(endTime.toString().substr(0, 2)));
    
        if (endPublishedDateTime > endDateTime && today > endDateTime) {
          orgCampaignList[i].imgURL = this.campaignOverImg;
        }

        //01:00の形式を1:00に変更
        if (CONST.ENDTIME_List.indexOf(orgCampaignList[i].endDate.slice(-5,-3)) >= 0) {
          var endTimeHour = orgCampaignList[i].endDate.slice(-5,-3).replace('0','');
          var endTimeTime = orgCampaignList[i].endDate.slice(-3);
          orgCampaignList[i].endDate = orgCampaignList[i].endDate.slice(0,-5) + endTimeHour + endTimeTime;
        }
    
        //タイトル50文字で切り捨て
        orgCampaignList[i].title = this.omittedText(
          orgCampaignList[i].title,
          CONST.TITLE_MAX_LENGTH
        );

        //サブタイトル100文字で切り捨て
        orgCampaignList[i].subtitle = this.omittedText(
          orgCampaignList[i].subtitle,
          CONST.SUBTITLE_MAX_LENGTH
        );

        //<br>,<BR>タグを改行コードに置換
        orgCampaignList[i].subtitle = orgCampaignList[i].subtitle.replace(/<br>/g, '\r\n').replace(/<BR>/g, '\r\n');
    
        //特別告知と地域別判断
        var specialAnnouncement = orgCampaignList[i].specialAnnouncement;
        //IEの場合、0埋めされていない"-"区切りの日付をnew DateするとInvalid Dateになるため"/"区切りにする
        var birthday = this.replaceDate(this.memberinfo.birthday);
        var birthDate = new Date(birthday);
        var birthMonth = birthDate.getMonth() + 1;
        var areaCampaign =  orgCampaignList[i].area;
        var area = '';
    
        if ((specialAnnouncement == '' || specialAnnouncement == null) && (areaCampaign == '' || areaCampaign == null)) {
          this.campaignList.push(orgCampaignList[i]);
        } else if (specialAnnouncement == birthMonth + CONST.MONTH) {
          //応募済み確認（単一応募のみ）
          if (orgCampaignList[i].apply) {
          //キャンペーン一覧に表示しない（明示的に何もしない）
          } else {
            this.specialCampaignList.push(orgCampaignList[i]);
          }
        } else if ( !(areaCampaign == '' || areaCampaign == null) && (specialAnnouncement == '' || specialAnnouncement == null)) { 
          //地域情報を","区切りから" "に変換
          area = areaCampaign.replace(/,/g, '\u3000');
          orgCampaignList[i].area = area;
    
          //地域情報からdata-areaに入れる値に整形
          for (var j = 0; j < CONST.AREA_LIST.length; j++) {
            area = area.replace(CONST.AREA_LIST[j].areaName,CONST.AREA_LIST[j].classArea);
          }
    
          //応募済み確認（単一応募のみ）
          if (orgCampaignList[i].apply) {
          //キャンペーン一覧に表示しない（明示的に何もしない）
          } else {
            //整形した値をリストに格納
            area = area.replace(/\u3000/g, '\x20');
            orgCampaignList[i].areaClass = area + "\x20" + "area-all";
            this.areaCampaignList.push(orgCampaignList[i]);
          }
        }
      }

      //jsonの値を形式チェックする
      function jsonCheck() {
        var check = 'true';
        //実施終了年月日の形式チェック（yyyy-mm-dd）
        var endDateCheck = checkDay(orgCampaignList[i].endDate);
        //実施終了時間の形式チェック（0:00～23:59）
        var endTimeCheck = checkTime(orgCampaignList[i].endTime);
        //掲載終了年月日の形式チェック（yyyy-mm-dd）
        var endPublishedDateCheck = checkDay(orgCampaignList[i].endPublishedDate);
        //掲載終了時間の形式チェック（0:00～23:59）
        var endPublishedTimeCheck = checkTime(orgCampaignList[i].endPublishedTime);

        //地域は複数選択可能のため、配列から文字列をチェックする
        //地域の形式チェック
        var areaCheckResult = '';
        for (var k = 0; k < CONST.AREA_LIST.length; k++) {
           areaCheckResult = orgCampaignList[i].area.indexOf(CONST.AREA_LIST[k].areaName);
          if ( areaCheckResult >= 0 ) {
            break;
          }
        }
        //日付と時間の形式チェック
        function checkDay(formatCheckDay){
          return formatCheckDay.match(/^[0-9]{4}-([0-9]{2})-([0-9]{2})$/);
        }
        //時間の形式チェック
        function checkTime(formatCheckTime){
          return formatCheckTime.match(/^[0-9]{2}:[0-9]{2}$/);
        }
        //形式に誤りか未入力があった場合、falseを返す
        //キャンペーン種別の判定
        if ( CONST.CAMPAIGNTYPE_LIST.indexOf(orgCampaignList[i].campaignType) <= -1 ){
          check = false;
        }
        //要項URLの判定
        else if  (orgCampaignList[i].link == '' ) {
          check = false;
        }
        //タイトルの判定
        else if ( orgCampaignList[i].title == '' ) {
          check = false;
        }
        //サブタイトルの判定
        else if ( orgCampaignList[i].subtitle == '' ){
          check = false;
        }
        //イメージ画像URLの判定
        else if ( orgCampaignList[i].imgURL == '' ){
          check = false;
        }
        //実施終了年月日と掲載終了年月日の判定
        else if ( endDateCheck == null || endTimeCheck == null){
          check = false;
        }
        //実施終了時間と掲載終了時間の判定
        else if ( endPublishedDateCheck  == null || endPublishedTimeCheck == null) {
          check = false;
        }
        //地域の判定
        else if ( areaCheckResult <= -1 && orgCampaignList[i].area != '') {
          check = false;
        }
        //特別告知条件の判定
        else if ( CONST.SPECIALANNOUNCEMENT_LIST.indexOf(orgCampaignList[i].specialAnnouncement) <= -1 ) {
          check = false;
        }
        //重複可否の判定
        else if ( CONST.APPLICATIONTYPE_LIST.indexOf(orgCampaignList[i].application_type) <= -1) {
          check = false;
        }
        return check;
      };

      // キャンペーン一覧が0件か確認
      if (this.campaignList.length == 0) {
        this.noCampaign(null);
      }

      // 地域限定campaignが0件か確認
      if (this.areaCampaignList.length == 0) {
        this.noAreaCampaign(null);
      }
    },
    
    // キャンペーン一覧を表示しない際に表示
    noCampaign(error) {
      if (error != null) {
        this.campaignMsg = CONST.ERRCAMPAIGN;
      } else {
        this.campaignMsg = CONST.NONCAMPAIGN;
      }
    },
    // 地域限定キャンペーンを表示しない際に表示
    noAreaCampaign(error) {
      if (error != null) {
        this.areaCampaignMsg = CONST.ERRCAMPAIGN;
      } else {
        this.areaCampaignMsg = CONST.NONCAMPAIGN;
      }
    },
  
    // 上限値を超えた分三点リーダーで省略する
    omittedText(text, length) {
      return text.length > length
        ? text.slice(0, length) + CONST.ELLIPSIS
        : text;
    },
  
    // 日付の"-"区切りを"/"に置換する
    replaceDate(date) {
      return date.replace(/-/g, '/');
    },

  },
  // vue instanceが作成した次第に実行する
  created() {
    // キャンペーン情報のJSONを読み取る
    axiosCampaign
      .get()
      .then((response) => {
        let orgCampaignList = response.data.campaignInformation;
        // キャンペーン情報を処理し表示する
        this.showCampaign(orgCampaignList);
      })
      .catch((error) => {
        this.noCampaign(error);
        this.noAreaCampaign(error);
      });
  },
  beforeMount() {
    //ページタイトルを設定
    document.title = this.$route.meta.title;
  }
};
</script>

<style scoped>
.m-media.theme-column > ul > li .m-media_title {
  display: block;
  word-wrap: break-word;
}
p {
  word-wrap: break-word;
}
</style>
