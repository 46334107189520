import newUrl from 'url';
import router from '../../router';
import axiosAuth from '../../services/axiosAuth';
import {
  PUBLICHTML,
  SOCIALAUTH,
  REFRESHBEFORE,
  MAINTAINURL,
  OVERLOADURL,
  LOGINCOOKIEKEY
} from '../../common/const';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

const state = {
  accesstoken: null,
  refreshtoken: null,
  autoLogin: false,
  loginButtonDisabled: false
};

const getters = {
  accesstoken: (state) => state.accesstoken,
  refreshtoken: (state) => state.refreshtoken,
  loginButtonDisabled: (state) => state.loginButtonDisabled
};

const mutations = {
  updateaccesstoken(state, accesstoken) {
    state.accesstoken = accesstoken;
  },
  updaterefreshtoken(state, refreshtoken) {
    state.refreshtoken = refreshtoken;
  },
  updateAutoLogin(state, autoLogin) {
    state.autoLogin = autoLogin;
  }
};

const actions = {
  // 自動ログイン処理
  async autoLogin({ commit, dispatch }) {
    //本登録完了から来た際に、cookieからリフレッシュトークンを読み取って、自動ログイン処理
    let cookieToken = Vue.$cookies.get('token');
    if (cookieToken) {
      await dispatch('refreshaccesstoken', cookieToken);
      Vue.$cookies.remove('token', null, 'sapporobeer.jp');
      return;
    }

    let accesstoken = localStorage.getItem('accesstoken');
    let expireAccessTime = localStorage.getItem('expireAccessTime');
    let refreshtoken = localStorage.getItem('refreshtoken');
    let expireRefreshTime = localStorage.getItem('expireRefreshTime');

    if (!refreshtoken) {
      refreshtoken = sessionStorage.getItem('refreshtoken');
      expireRefreshTime = sessionStorage.getItem('expireRefreshTime');
    } else {
      commit('updateAutoLogin', true);
    }
    if (!accesstoken && !refreshtoken) return;

    // jwtある場合はvuexのstoreに更新する
    let now = Math.floor(new Date() / 1000);
    // アクセストーク有効期限と現在時間と差 ms単位
    let expiresInAccess = expireAccessTime - now;

    // リフレッシュトーク有効期限と現在時間と差 ms単位
    let expiresInRefresh = expireRefreshTime - now;

    // アクセストーク期限切れの場合はリフレッシュトークンを確認する
    if (!expireAccessTime || expiresInAccess <= REFRESHBEFORE) {
      // リフレッシュトークン期限切れの場合はログアウト処理し、ログイン画面へ遷移する
      if (expiresInRefresh <= REFRESHBEFORE) {
        dispatch('logout');
        dispatch('setLoginMessage', 'timeout');
        router.push('/login?internalFlg=1&purposeFlg=&url=%2F');
      } else {
        // リフレッシュトークン有効の場合はjwt更新処理する
        await dispatch('refreshaccesstoken', refreshtoken);
      }
    } else {
      commit('updateaccesstoken', accesstoken);
      commit('updaterefreshtoken', refreshtoken);
      await dispatch('loadMember', accesstoken);
    }
  },
  // ログイン処理
  login({ rootState, dispatch }, authData) {
    //ログインボタンをクリック不可にする
    state.loginButtonDisabled = true;
    axiosAuth
      .post('/member/auth/', {
        mailaddress: authData.email,
        plainpassword: authData.password
      })
      // 成功した場合は、jwtと会員情報をvuexのstoreに更新し、マイアカウント画面へ遷移する
      .then((response) => {
        let responseObj = JSON.parse(response.data.body);
        responseObj.autoLogin = authData.autoLogin;
        dispatch('setAuthData', responseObj);
        dispatch('setLoginMessage', '');
        //ログイン成功時、cookieにGA4に送信する値を格納する
        let now = new Date();
        let nowDate = now.getFullYear() + '-' + ('0'+(now.getMonth()+1)).slice(-2) + '-' + ('0'+now.getDate()).slice(-2);
        let nowTime = ('0'+now.getHours()).slice(-2) + ':' + ('0'+now.getMinutes()).slice(-2) + ':' + ('0'+now.getSeconds()).slice(-2);
        let loginGa4 =  {
          "event":'login',
          "suid":responseObj.memberinfo.suid,
          "profile_update_date": nowDate + ' ' + nowTime
        }
        document.cookie = LOGINCOOKIEKEY + "=" + JSON.stringify(loginGa4) + "; path=/; max-age=15984000; samesite=strict; domain=.sapporobeer.jp; secure;";
        // storeにurlパラメータが存在するかを確認する
        if (rootState.loginParams.loginParams.url) {
          let url = rootState.loginParams.loginParams.url;
          let purposeFlg = rootState.loginParams.loginParams.purposeFlg;
          let internalFlg = rootState.loginParams.loginParams.internalFlg;
          // URLパラメータはサッポロドメイン内に限定する
          let parseUrl = newUrl.parse(url);
          if (parseUrl.host && parseUrl.host.slice(-15) != '.sapporobeer.jp') {
            window.location.assign('/');
          }
          //URLパラメータにより遷移先を指定する
          else if (internalFlg === '' || internalFlg === '1') {
            window.location.assign(url);
          } else {
            if (purposeFlg === 'campaign') {
              window.location.assign(PUBLICHTML.url + url);
            }
            // LINE・Twiiterキャンペーンのログイン後、suidと紐付け処理
            else if (
              purposeFlg === 'lineCampaign' ||
              purposeFlg === 'twitterCampaign'
            ) {
              let provider = '';
              if (purposeFlg === 'lineCampaign') {
                provider = 'line';
              }
              if (purposeFlg === 'twitterCampaign') {
                provider = 'twitter';
              }
              dispatch('campaignLogin', provider);
            }
            else {
              window.location.assign(url);
            } 
          }
        } else {
          window.location.assign('/');
        }
      })
      .catch((error) => {
        //認証のエラーメッセージを指定する
        if (!error.response) {
          dispatch('setLoginMessage', 500);
        } else {
          if (error.response.status === 503) {
            window.location.assign(MAINTAINURL);
            return;
          } else if (error.response.status === 429) {
            window.location.assign(OVERLOADURL);
            return;
          } else if (error.response.status === 404 && error.response.data === 70) {
            dispatch('logout');
            dispatch('setLoginMessage', error.response.data);
          } else {
            dispatch('setLoginMessage', error.response.status);
          }
        }
        //ログインボタンをクリック可にする
        setTimeout(() => {
          state.loginButtonDisabled = false;
        }, 1000);
      });
  },
  // jwt更新処理
  async refreshaccesstoken({ rootState, state, dispatch }, refreshtoken) {
    await axiosAuth
      .post('/member/refreshtoken/', {
        refreshtoken: refreshtoken
      })
      // 成功した場合は、jwtと会員情報をvuexのstoreに更新する
      .then((response) => {
        let responseObj = JSON.parse(response.data.body);
        responseObj.autoLogin = state.autoLogin;
        dispatch('setAuthData', responseObj);
        // storeにurlパラメータが存在するかを確認する
        if (rootState.loginParams.loginParams.url) {
          let url = rootState.loginParams.loginParams.url;
          let purposeFlg = rootState.loginParams.loginParams.purposeFlg;
          let internalFlg = rootState.loginParams.loginParams.internalFlg;
          dispatch('setParams', { url: '', purposeFlg: '', internalFlg: '' });
          // URLパラメータはサッポロドメイン内に限定する
          let parseUrl = newUrl.parse(url);
          if (parseUrl.host && parseUrl.host.slice(-15) != '.sapporobeer.jp') {
            window.location.assign('/');
          } else if (internalFlg === '' || internalFlg === '1') {
            router.push(url);
          } else {
            if (purposeFlg === 'campaign') {
              window.location.assign(PUBLICHTML.url + url);
            } else if (purposeFlg === 'lineCampaign') {
              window.location.assign(PUBLICHTML.url + url);
            } else if (purposeFlg === 'twitterCampaign') {
              window.location.assign(PUBLICHTML.url + url);
            } else {
                window.location.assign(url);
            }
          }
        }
      })
      .catch((error) => {
        if (!error.response) {
          dispatch('setLoginMessage', 500);
        } else {
          if (error.response.status === 503) {
            window.location.assign(MAINTAINURL);
            return;
          } else if (error.response.status === 429) {
            window.location.assign(OVERLOADURL);
            return;
          } else if (error.response.status === 404 && error.response.data === 70) {
            dispatch('logout');
          } else {
            dispatch('logout');
            dispatch('setLoginMessage', 'timeout');
            router.push('/login?internalFlg=1&purposeFlg=&url=%2F');
          }
        }
      });
  },
  // 認証成功後、jwtと会員情報をvuexのstoreに更新する
  setAuthData({ commit, dispatch }, authData) {
    commit('updateaccesstoken', authData.accesstoken);
    commit('updaterefreshtoken', authData.refreshtoken);
    commit('updatememberinfo', authData.memberinfo);
    commit('updateAutoLogin', authData.autoLogin);

    dispatch('setLoginMessage', '');
    dispatch('setSocialMessage', '');
    // 自動ログインをチェックした場合は、jwtをローカルストレージに格納する
    if (authData.autoLogin === true) {
      localStorage.setItem('refreshtoken', authData.refreshtoken);
      localStorage.setItem('expireRefreshTime', authData.expireRefreshTime);
    } else {
      sessionStorage.setItem('refreshtoken', authData.refreshtoken);
      sessionStorage.setItem('expireRefreshTime', authData.expireRefreshTime);
    }

    localStorage.setItem('expireAccessTime', authData.expireAccessTime);
    localStorage.setItem('accesstoken', authData.accesstoken);
    // アクセストーク有効期限なる前に更新処理する
    setTimeout(() => {
      dispatch('refreshaccesstoken', authData.refreshtoken);
    }, (authData.expireAccessTime - Math.floor(new Date() / 1000)) * 1000);
  },
  // ログアウト処理
  logout({ state, commit, dispatch }) {
    //ログアウトする際にトークンを廃棄、ブラックリスト処理
    if (state.accesstoken && state.refreshtoken) {
      axiosAuth.post('/member/logout', {
        accesstoken: state.accesstoken,
        refreshtoken: state.refreshtoken
      });
    }
    localStorage.removeItem('accesstoken');
    localStorage.removeItem('expireAccessTime');
    sessionStorage.removeItem('refreshtoken');
    sessionStorage.removeItem('expireRefreshTime');
    localStorage.removeItem('refreshtoken');
    localStorage.removeItem('expireRefreshTime');

    commit('updateaccesstoken', null);
    commit('updaterefreshtoken', null);
    commit('updatememberinfo', {});
    commit('updateAutoLogin', false);

    dispatch('setLoginMessage', '');
    dispatch('setSocialMessage', '');
    dispatch('setParams', {});
  },
  // LINE、Twitterキャンペーンなどのため、ログイン後ソーシャルプラス連携処理
  async campaignLogin({ rootState, dispatch }, provider) {
    //urlパラメータをcallback用に整形
    let params =
      '?params=' +
      rootState.loginParams.loginParams.url +
      '|||' +
      rootState.loginParams.loginParams.internalFlg +
      '|||' +
      rootState.loginParams.loginParams.purposeFlg;

    //遷移元がわかる場合は、パラメータに遷移元を追加、SNSのサッポロ認証失敗やキャンセルした場合は遷移元へ戻る
    if (document.referrer) {
      params = params + '|||' + 'campaign' + '|||' + document.referrer;
    }
    let socialLink =
      SOCIALAUTH.baseUrl +
      provider +
      SOCIALAUTH.auth +
      window.location.origin +
      SOCIALAUTH.registCampaignCallback +
      params;

    // 会員の連携状況を確認する
    await axiosAuth
      .post('/social/providers', { accesstoken: state.accesstoken })
      .then((response) => {
        // 該当SNSとまだ紐付けていない場合
        if (
          response.data.status === 'ok' &&
          response.data.providers.indexOf(provider) === -1
        ) {
          window.location.assign(socialLink);
        } else {
          //既に紐付けた場合はキャンペーンフォームへ遷移する
          window.location.assign(
            PUBLICHTML.url + rootState.loginParams.loginParams.url
          );
        }
      })
      .catch((error) => {
        if (!error.response) {
          dispatch('logout');
          dispatch('setLoginMessage', 'sever');
        } else {
          if (error.response.status === 503) {
            window.location.assign(MAINTAINURL);
            return;
          } else if (error.response.status === 404) {
            window.location.assign(socialLink);
            return;
          }
          dispatch('logout');
          dispatch('setLoginMessage', 'socialPlusError');
        }
      });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
