import axiosAuth from '../../services/axiosAuth';
import router from '../../router';
import { MAINTAINURL, OVERLOADURL, COOKIEKEY } from '../../common/const';
//会員情報の初期化
const state = {
  memberinfo: {
    synergyid: '',
    mailaddress: '',
    phonenumber: '',
    zipcode: '',
    prefecture: '',
    suid: '',
    birthday: '',
    namesei: '',
    namemei: '',
    nameseikana: '',
    namemeikana: '',
    sex: '',
    addr1: '',
    addr2: '',
    mailmagatype: '',
    memberflag: '',
    mailmaga1: '',
    mailmaga2: '',
    mailmaga3: '',
    mailmaga4: '',
    campaignid: ''
  }
};

const getters = {
  memberinfo: (state) => state.memberinfo
};

const mutations = {
  updatememberinfo(state, memberinfo) {
    if (memberinfo.mail) {
      state.memberinfo.synergyid = memberinfo.id;
      state.memberinfo.mailaddress = memberinfo.mail;
      state.memberinfo.phonenumber = memberinfo.tel;
      state.memberinfo.zipcode = memberinfo.zip;
      state.memberinfo.prefecture = memberinfo.pref.value;
      state.memberinfo.suid = memberinfo.suid;
      state.memberinfo.birthday = memberinfo.birth;
      state.memberinfo.namesei = memberinfo.nameSei;
      state.memberinfo.namemei = memberinfo.nameMei;
      state.memberinfo.nameseikana = memberinfo.kanaSei;
      state.memberinfo.namemeikana = memberinfo.kanaMei;
      state.memberinfo.sex = memberinfo.sex.value;
      state.memberinfo.addr1 = memberinfo.address1;
      state.memberinfo.addr2 = memberinfo.address2;
      state.memberinfo.mailmagatype = memberinfo.htmlMailFlg.value;
      state.memberinfo.memberflag = memberinfo.memberKind;
      state.memberinfo.mailmaga1 = memberinfo.mailMaga1.value;
      state.memberinfo.mailmaga2 = memberinfo.mailMaga2.value;
      state.memberinfo.mailmaga3 = memberinfo.mailMaga3.value;
      state.memberinfo.mailmaga4 = memberinfo.mailMaga4.value;
      if (memberinfo.entryCampaignId != null) {
        var campaignIdList = memberinfo.entryCampaignId;
        for (var i = 0; i < campaignIdList.length; i++) {
          state.memberinfo.campaignid =
            state.memberinfo.campaignid + memberinfo.entryCampaignId[i].value;
          if (i != campaignIdList.length - 1) {
            state.memberinfo.campaignid = state.memberinfo.campaignid + ',';
          }
        }
      } else {
        state.memberinfo.campaignid = '';
      }
    } else {
      state.memberinfo = {
        synergyid: '',
        mailaddress: '',
        phonenumber: '',
        zipcode: '',
        prefecture: '',
        suid: '',
        birthday: '',
        namesei: '',
        namemei: '',
        nameseikana: '',
        namemeikana: '',
        sex: '',
        addr1: '',
        addr2: '',
        mailmagatype: '',
        memberflag: '',
        mailmaga1: '',
        mailmaga2: '',
        mailmaga3: '',
        mailmaga4: '',
        campaignid: ''
      };
    }
  }
};

const actions = {
  //jwtを使って会員情報を取得
  async loadMember({ commit, dispatch }, accesstoken) {
    await axiosAuth
      .post('/member/infobysuid/', {
        accesstoken: accesstoken
      })
      .then((response) => {
        let responseObj = JSON.parse(response.data.body);
        document.cookie = COOKIEKEY + "=" + responseObj.memberinfo.suid + "; path=/; max-age=15984000; samesite=strict; domain=.sapporobeer.jp; secure;";
        commit('updatememberinfo', responseObj.memberinfo);
      })
      .catch((error) => {
        if (!error.response) {
          dispatch('logout');
          dispatch('setLoginMessage', 500);
        } else {
          if (error.response.status === 503) {
            window.location.assign(MAINTAINURL);
            return;
          } else if (error.response.status === 429) {
            window.location.assign(OVERLOADURL);
            return;
          } else if (error.response.status === 404 && error.response.data === 70) {
            dispatch('logout');
          } else {
            dispatch('logout');
            dispatch('setLoginMessage', error.response.status);
          }
        }
        router.push('/login?internalFlg=1&purposeFlg=&url=%2F');
      });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
